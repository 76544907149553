import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Typography,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    Paper,
    Grid,
    IconButton,
    Tooltip
} from '@mui/material';
import { DataGrid, GridToolbar, GridValueFormatterParams } from '@mui/x-data-grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh'; // Import the refresh icon
import { url } from '../../utils/constants';
import MultipleFiltersDataGrid from '../MultipleFiltersDataGrid.js';

const renderStatusIcon = (value) => {
    // console.log(value)
    return value.value == 'Sim' ? (
        <Tooltip title="Sim">
            <CheckCircleIcon style={{ color: 'green' }} />
        </Tooltip>
    ) : (
        <Tooltip title="Não">
            <CancelIcon style={{ color: 'red' }} />
        </Tooltip>
    );
};

function CourtesyCustomers() {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [ignoreDate, setIgnoreDate] = useState(false);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const apiRef = React.useRef();  // Create a reference to the DataGrid API



    
    useEffect(() => {
        fetchCourtesyCustomers();
    }, []);


    const fetchCourtesyCustomers = async () => {
        try {
            setLoading(true);

            const token = localStorage.getItem('token');
            const response = await axios.get(`${url}/api/reports/courtesyCustomers`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            const data = response.data.map((item, index) => ({
                ...item,
                id: index + 1, // Adding a unique ID for the DataGrid
                ISFuncionario: item.ISFuncionario === 1 ? 'Sim' : 'Não',
                ISParceiro: item.ISParceiro === 1 ? 'Sim' : 'Não',
                ValorContrato: item.ValorContrato ?? 0,  // Ensure default value
                MediaFaturas: item.MediaFaturas ?? 0,  // Ensure default value
                ISFormadorOpiniao: item.ISFormadorOpiniao === 1 ? 'Sim' : 'Não',
            }));
            
            console.log(data)

            setRows(data);
        } catch (error) {
            console.error('Error fetching forms:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="xl">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Clientes cortesia
                </Typography>
                {
                    !loading &&
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <IconButton onClick={fetchCourtesyCustomers} aria-label="Recarregar">
                            <RefreshIcon />
                        </IconButton>
                    </Box>
                }
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Paper elevation={3} sx={{ height: 600, padding: 3 }}>
                    <DataGrid
                        rows={rows}
                        columns={[
                            { field: 'IDContrato', headerName: 'Contrato', width: 90 },
                            {
                                field: 'ValorContrato',
                                headerName: 'Valor contrato',
                                width: 120,        
                                valueFormatter: (value) => {
                                    console.log('ValorContrato:', value); // Debugging log
                                    const num = Number(value);
                                    return !isNaN(num) && num !== 0
                                        ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(num)
                                        : 'R$ 0,00';
                                }
                            },
                            {
                                field: 'MediaFaturas',
                                headerName: 'Média faturas',
                                width: 120,
                                valueFormatter: (value) => {
                                    // console.log('MediaFaturas:', value); // Debugging log
                                    const num = Number(value);
                                    return !isNaN(num) && num !== 0
                                        ? new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 }).format(num)
                                        : 'R$ 0,00';
                                }
                            },
                            
                            
                            
                            { field: 'Nome', headerName: 'Nome', width: 250 },
                            { 
                                field: 'CPF', 
                                headerName: 'CPF', 
                                width: 150, 
                                valueFormatter: (value) => {
                                    if (!value) return ''; // Avoid formatting null/undefined
                                    return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4'); 
                                }
                            },
                            { 
                                field: 'CNPJ', 
                                headerName: 'CNPJ', 
                                width: 180, 
                                valueFormatter: (value) => {
                                    if (!value) return ''; // Avoid formatting null/undefined
                                    return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
                                }
                            },                            
                            { field: 'TipoPessoa', headerName: 'Tipo', width: 220 },
                            {
                                field: 'ISFuncionario',
                                headerName: 'Funcionário?',
                                width: 90,
                                renderCell: (value) => renderStatusIcon(value),
                            },
                            {
                                field: 'ISParceiro',
                                headerName: 'Parceiro?',
                                width: 90,
                                renderCell: (value) => renderStatusIcon(value),
                            },
                            {
                                field: 'ISFormadorOpiniao',
                                headerName: 'Formador de opinião?',
                                width: 120,
                                renderCell: (value) => renderStatusIcon(value),
                            },
                            { field: 'EndEmail', headerName: 'E-mail', width: 200 },
                        ]}
                        pageSize={5}
                        checkboxSelection
                        loading={loading}
                        // slots={{
                        //     toolbar: MultipleFiltersDataGrid,
                        //   }}
                        // slots={{ toolbar: MultipleFiltersDataGrid }}
                        slots={{ toolbar: GridToolbar }}
                    />

                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default CourtesyCustomers;
