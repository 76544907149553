import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField,Divider,Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { GoogleLogin, useGoogleLogin  } from '@react-oauth/google';
import { url } from '../../../utils/constants';
// components
import Iconify from '../../../components/iconify';
import { useHistory } from 'react-router-dom';


// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const login = useGoogleLogin({
    onSuccess: (response) => handleGoogleLoginSuccess(response),
    onError: () => console.log('Login Failed'),
    flow: 'auth-code',
  });

  useEffect(() => {
    // Get the URL parameters
    const params = new URLSearchParams(window.location.search);
    const err = params.get('err');
    const token = params.get('token');
    const email = params.get('email');
    const name = params.get('name');

    // Only check the referrer if err or token and email are present
    if (err || (token && email)) {
      const referrer = document.referrer;
      const allowedReferrer = 'https://accounts.google.com/';

      // Validate the referrer
      if (!referrer.startsWith(allowedReferrer)) {
        navigate('/')
        return;
      }

      if (err) {
        // Display error messages based on the error code
        switch (err) {
          case '1':
            setError('Não existe usuário vinculado com essa conta Google.');
            break;
          case '2':
            setError('A conta está suspensa.');
            break;
          case '3':
            setError('Ocorreu um erro ao autenticar, tente novamente.');
            break;
          default:
            setError('Ocorreu um erro inesperado, contate o administrador.');
        }
      } else if (token && email) {
        // Save the token and email to session storage
        localStorage.setItem('token', token);
        localStorage.setItem('email', email);
        localStorage.setItem('name', name);

        if(Cookies.get('nextUrl')) {
          // console.log(Cookies.get('nextUrl'))
          const ck = Cookies.get('nextUrl');
          Cookies.remove('nextUrl')
          window.location.href = ck;
        }else{
          // Redirect to dashboard
          navigate('/dashboard', { replace: true });
        }
      }
    }
  }, [history]);
  const handleGoogleLoginSuccess = async () => {
    // console.log(response)
    try {
      setLoading(true);
      window.open('https://forms-api.redeatel.com.br/auth/google', '_self');
      // const res = await axios.post(`${url}/google-auth`, response);
      // console.log(response)
      // localStorage.setItem('token', res.data.token);
      // localStorage.setItem('name', res.data.user.name);

      // navigate('/dashboard', { replace: true });
    } catch (err) {
      setError('Google login failed. Please contact admin if you do not have an account.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      // Make a POST request to your backend login endpoint
      const response = await axios.post(`${url}/login`, {
        email,
        password,
      });

      // Store the token in local storage
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('name', response.data.user.name);
      localStorage.setItem('email', email);

      if(Cookies.get('nextUrl')) {
        const ck = Cookies.get('nextUrl');
        Cookies.remove('nextUrl')
        window.location.href = ck;
      }else{
        // Redirect to dashboard
        navigate('/dashboard', { replace: true });
      }

    } catch (err) {
      setError('Credenciais inválidas');
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack direction="row" spacing={2}>
        <Button fullWidth size="large" color="inherit" variant="outlined" onClick={() => handleGoogleLoginSuccess()}>
          <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
        </Button>
        {/* <GoogleLogin
          onSuccess={handleGoogleLoginSuccess}
          onError={() => setError('Google login failed')}
        /> */}
      </Stack>


      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OU
        </Typography>
      </Divider>


      <Stack spacing={3}>
        <TextField 
          name="email" 
          label="Endereço e-mail" 
          value={email}
          onChange={(e) => setEmail(e.target.value)} 
          required 
        />

        <TextField
          name="password"
          label="Senha"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)} 
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover">
          Esqueceu a senha?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
        Entrar
      </LoadingButton>
    </form>
  );
}
