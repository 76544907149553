import React, { useEffect, useState } from 'react';
import { TextField, Typography } from '@mui/material';

function EditableText({onFormNameChange,defaultText}) {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(defaultText || 'Double-click to edit me');

  const handleDoubleClick = () => setIsEditing(true);
  const handleBlur = () => setIsEditing(false);


  useEffect(() => {
    onFormNameChange(text)
  }, [text]);  // Empty array ensures it runs only once after the initial rende

  return (
    <div>
      {isEditing ? (
        <TextField
          value={text}
          onChange={(e) => setText(e.target.value)}
          onBlur={handleBlur}
          
          fullWidth
          autoFocus
          style={{
            border: 'none',
            outline: 'none'
          }}
        />
      ) : (
        <Typography variant="h6" sx={{fontWeight: 400}} onDoubleClick={handleDoubleClick}>
          {text}
        </Typography>
      )}
    </div>
  );
}

export default EditableText;
