import React, { useEffect, useRef, useState } from 'react';
import { Button, Box, Container, Typography, Divider } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import EditableText from '../components/EditableText';
import Section from '../components/Section';
import { url } from '../utils/constants';



function FormBuilder({ onSave }) {

  const navigate = useNavigate();

  const [formName, setFormName] = useState(''); // State to hold the form name

  // Function to update form name
  const handleFormNameChange = (newFormName) => {
    setFormName(newFormName);
  };
    
  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current && sections.length === 0) {
        buttonRef.current.click();  // Simulate the button click
    }
  }, []);  // Empty array ensures it runs only once after the initial rende

  const [sections, setSections] = useState([]);

  const addSection = () => {
    setSections([...sections, { title: '', inputs: [], isCollapsed: false }]);
  };

  const updateSection = (index, newSection) => {
    const updatedSections = [...sections];
    updatedSections[index] = newSection;
    setSections(updatedSections);
  };

  const toggleCollapse = (index) => {
    const updatedSections = [...sections];
    updatedSections[index].isCollapsed = !updatedSections[index].isCollapsed;
    setSections(updatedSections);
  };

  const deleteSection = (index) => {
    if (window.confirm('Tem certeza que deseja apagar a seção')) {
      const updatedSections = sections.filter((_, i) => i !== index);
      setSections(updatedSections);
    }
  };

  const saveForm = async () => {
    // onSave(sections);
    const formData = {
      json_content: {...sections},
      name: formName,
      status: true,
      is_public: false,
      allow_multiple: true
    }

    const token = localStorage.getItem('token');

    const headers = {
      Authorization: `Bearer ${token}`, // Send the token in the header
    }

    try{
      const response = await axios.post(`${url}/new-form`, formData, {headers: {...headers}});

      if(response.status === 201){
        await Swal.fire({
          title: 'Formulário criado com sucesso',
          icon: 'success',
          text: 'Você será redirecionado para a página de formulários',
          timer: 4000
        })

        navigate('/dashboard/forms')
      }
    }catch (error) {
      await Swal.fire({
        title: 'Falha ao processar solicitação',
        icon: 'error',
        text: 'Ocorreu um erro ao enviar seu pedido, tente novamente ou contacte o administrador do sistema',
        timer: 4000
      })
    }

  };

  return (
    <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 4 }}>
            Cadastro de formulário
        </Typography>

        <Divider sx={{ mb: 4 }} />

        <EditableText onFormNameChange={handleFormNameChange} defaultText={'Formulário em andamento (duplo clique para editar)'} />

        <Box sx={{ mt: 4 }}>
        {sections.map((section, index) => (
            <Section
            key={index}
            section={section}
            updateSection={(newSection) => updateSection(index, newSection)}
            toggleCollapse={() => toggleCollapse(index)}
            deleteSection={() => deleteSection(index)} // Pass delete handler
            />
        ))}
            {/* <br /> */}
            
            <Button ref={buttonRef} variant="contained" style={{background: 'rgb(159, 170, 181)'}} onClick={addSection} sx={{mt: 2}}>
                Adicionar seção
            </Button>
            <div style={{clear: 'both'}} />
            {
                sections.length > 0 &&
                <Button variant="contained" color="secondary" sx={{ mt: 2 }} onClick={saveForm}>
                    Salvar
                </Button>
            }
        </Box>
    </Container>
  );
}

export default FormBuilder;