import React, { useState } from 'react';
import { 
  Button, Box, Container, Typography, Divider, TextField, Card, CardContent, 
  CircularProgress, Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Paper, Snackbar, Alert 
} from '@mui/material';
import axios from 'axios';

function UrlShortner() {
  const [longUrl, setLongUrl] = useState("");
  const [shortUrl, setShortUrl] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [urls, setUrls] = useState([]);
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");


  const isValidHttpUrl = (string) => {
    try {
      const url = new URL(string.trim());
      return url.protocol === "http:" || url.protocol === "https:";
    } catch (_) {
      return false;
    }
  }

  const shortenURL = async () => {
    if (!longUrl.trim() || !isValidHttpUrl(longUrl)) {
      setSnackbarMessage({ message: "Por favor, insira uma URL válida.", severity: "warning" });
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    const apiUrl = "https://atel.psi.br/yourls-api.php";
    const signature = "2dbca69c54";

    try {
      const response = await axios.get(apiUrl, {
        params: {
          signature: signature,
          action: "shorturl",
          format: "json",
          url: longUrl,
        },
      });

      setShortUrl(response.data.shorturl);
      setError(null);
      setSnackbarMessage({message: "URL encurtada com sucesso!", severity: 'success'});
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const data = err.response.data;
        if (data.status === "fail" && data.code === "error:url") {
          setShortUrl(data.shorturl);
          setError(null);
          setSnackbarMessage("Essa URL já foi encurtada.");
          setSnackbarMessage({message: "Essa URL já foi encurtada.", severity: 'info'});
        } else {
          setSnackbarMessage("Erro ao processar a solicitação.");
          setSnackbarMessage({message: "Erro ao processar a solicitação.", severity: 'warning'});
        }
      } else {
        setSnackbarMessage({message: "Erro desconhecido", severity: 'warning'});
      }
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const fetchUrls = async () => {
    setSnackbarMessage({message: "Em desenvolvimento", severity: 'warning'});
    setSnackbarOpen(true);
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" sx={{ mb: 4 }}>
        Encurtador de URL
      </Typography>
      <Divider sx={{ mb: 4 }} />

      <Card sx={{ mt: 4, mb: 4 }}>
        <CardContent>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Informe uma URL longa válida
          </Typography>

          <TextField
            label="Insira a URL"
            type="text"
            value={longUrl}
            onChange={(e) => setLongUrl(e.target.value)}
            fullWidth
            sx={{ mb: 2, mt: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={shortenURL}
            disabled={loading}
            sx={{ mr: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : "Encurtar URL"}
          </Button>
          <Button variant="outlined" onClick={() => { setOpen(true); fetchUrls(); }}>
            Listar URLs
          </Button>

          {shortUrl && (
            <Box mt={2}>
              <Typography variant="body1">
                URL Encurtada: <a href={shortUrl} target="_blank" rel="noopener noreferrer">{shortUrl}</a>
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      {/* Modal para listar URLs */}
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>URLs Encurtadas</DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Curta</strong></TableCell>
                    <TableCell><strong>Original</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {urls.map((url, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <a href={url.shorturl} target="_blank" rel="noopener noreferrer">{url.shorturl}</a>
                      </TableCell>
                      <TableCell>
                        <a href={url.url} target="_blank" rel="noopener noreferrer">{url.url}</a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
      </Dialog>

      {/* Snackbar para notificações */}
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={3000} 
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarMessage?.severity} sx={{ width: '100%' }}>
          {snackbarMessage?.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default UrlShortner;
