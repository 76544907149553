import PropTypes from 'prop-types';
import { set, sub } from 'date-fns';
import { noCase } from 'change-case';
import { faker } from '@faker-js/faker';
import { useState, useEffect } from 'react';
import Swal from "sweetalert2"
import axios from 'axios';
import { url } from '../../../utils/constants';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

// ----------------------------------------------------------------------

const notifyAction = async (id = null, action = '') => {
  try {

    const token = localStorage.getItem('token');

    const headers = {
      Authorization: `Bearer ${token}`, // Send the token in the header
    }

    const response = await axios.post(`${url}/api/notifications/action`, {nid: id, action: action}, {headers: {...headers}});

    if (response) {
      // Optionally, refresh the form list or update the state
      Swal.fire({
        title: 'Ação realizada com sucesso',
        icon: 'success'
      })

      console.log('Action performed successfully');
    } else {
      console.error('Failed to perform action');
    }
  } catch (error) {
    console.error('Error performing action:', error);
  }
}


const NOTIFICATIONS = [
  {
    id: faker.datatype.uuid(),
    title: 'Um novo formulário foi criado',
    description: 'Captacao de leads para NGE',
    avatar: null,
    type: 'mail',
    createdAt: set(new Date(), { hours: 10, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: faker.name.fullName(),
    description: 'respondeu um formulario',
    avatar: '/assets/images/avatars/avatar_2.jpg',
    type: 'friend_interactive',
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: 'Um novo formulário foi criado',
    description: 'Coleta de nomes para a confra 2024',
    avatar: null,
    type: 'mail',
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  // {
  //   id: faker.datatype.uuid(),
  //   title: 'Delivery processing',
  //   description: 'Your order is being shipped',
  //   avatar: null,
  //   type: 'order_shipped',
  //   createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
  //   isUnRead: false,
  // },
];

export default function NotificationsPopover() {
  const [notifications, setNotifications] = useState([]);

  const totalUnRead = notifications.filter((item) => item.is_read === false).length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        is_read: false,
      }))
    );
  };

  const fetchNotifies = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${url}/api/notifications`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setNotifications(response.data)
      console.log(response.data)
    } catch (error) {
      // navigate('/404');
      console.error('Error fetching forms:', error);
    }
  };

  useEffect(() => {
    fetchNotifies();
  }, []);

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notificações</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Há {totalUnRead} notificações não lidas
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Marcar todas como lidas">
              <IconButton color="primary" onClick={async () => await notifyAction(null, 'markAllRead')}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Novas
              </ListSubheader>
            }
          >
            {notifications.filter((notification) => !notification.is_read).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Lidas
              </ListSubheader>
            }
          >
            {notifications.filter((notification) => notification.is_read).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple onClick={() => document.location.href = '/dashboard/notifications'}>
            Abrir todas
          </Button>
        </Box>
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.string,
    id: PropTypes.number,
    is_read: PropTypes.bool,
    action: PropTypes.string,
    message: PropTypes.string,
    goto: PropTypes.string
  }),
};

function NotificationItem({ notification }) {
  const { avatar, action } = renderContent(notification);

  return (
    <ListItemButton
    onClick={async () => {
      await notifyAction(notification.id, 'markRead')

      document.location.href = notification.goto
    }}
    sx={{
      py: 1.5,
      px: 2.5,
      mt: '1px',
      bgcolor: notification.is_read ? '#fff' : 'action.selected', // Background color based on read/unread
      '&:hover': {
        bgcolor: notification.is_read ? '#f5f5f5' : 'action.hover', // Hover effect
      },
    }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={action}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const action = (
    <Typography variant="subtitle2">
      {notification.action}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.message}
      </Typography>
    </Typography>
  );

  if (notification.action.includes('resposta')) {
    return {
      avatar: <img alt={notification.action} src="/assets/icons/ic_notification_chat.svg" />,
      action,
    };
  }
  if (notification.action.includes('formulário')) {
    return {
      avatar: <img alt={notification.action} src="/assets/icons/ic_notification_mail.svg" />,
      action,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.action} src="/assets/icons/ic_notification_mail.svg" />,
      action,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.action} src="/assets/icons/ic_notification_chat.svg" />,
      action,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    action,
  };
}
