import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import FormBuilder from './pages/FormBuilder';
import Forms from './pages/Forms';
import Form from './pages/Form';
import Page403 from './pages/Page403';
import AuthGuard from './components/AuthGuard';
import FormRender from './pages/FormRender';
import App from './pages/Report';
import Success from './pages/Success';
import NotificationsPage from './pages/Notifications';
import RegisterUser from './pages/Register';
import EditUserProfile from './pages/Profile';
import QuizEditor from './pages/QuizEdit';
import QuizBuilder from './pages/QuizBuilder';
import Quiz from './pages/Quiz';
import QuizRender from './pages/QuizRender';
import QuizReport from './pages/QuizReport';
import FormEditor from './pages/FormEditor';
import Reports from './pages/Reports';
import ReportsLoad from './pages/ReportsLoad';
import UrlShortner from './pages/UrlShortner';


// ----------------------------------------------------------------------



export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (
        <AuthGuard requiredRole={['any']}>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: (
          <AuthGuard requiredRole={[1]}>
            <UserPage /> 
          </AuthGuard>
        )},
        { path: 'new-user', element: (
          <AuthGuard requiredRole={[1]}>
            <RegisterUser /> 
          </AuthGuard>
        )},
        { path: 'profile', element: (
          <AuthGuard requiredRole={[1,2,3]}>
            <EditUserProfile /> 
          </AuthGuard>
        )},
        { path: 'reports', element: (
          <AuthGuard requiredRole={[1,2,3]}>
            <Reports /> 
          </AuthGuard>
        )},
        { path: 'reports/:uuid', element: (
          <AuthGuard requiredRole={[1,2,3]}>
            <ReportsLoad /> 
          </AuthGuard>
        )},
        { path: 'report/:id', element: (
          <AuthGuard requiredRole={[1,2]}>
            <App /> 
          </AuthGuard>
        )},
        { path: 'quiz/:id', element: (
          <AuthGuard requiredRole={[1,2]}>
            <QuizReport /> 
          </AuthGuard>
        )},
        { path: 'edit-quiz/:id', element: (
          <AuthGuard requiredRole={[1,2]}>
            <QuizEditor /> 
          </AuthGuard>
        )},
        { path: 'notifications', element: (
          <AuthGuard requiredRole={[1,2]}>
            <NotificationsPage /> 
          </AuthGuard>
        )},
        { path: 'add-form', element: 
          <AuthGuard requiredRole={[1,2]}>
            <FormBuilder /> 
          </AuthGuard>
         },
        { path: 'edit-form/:id', element: 
          <AuthGuard requiredRole={[1,2]}>
            <FormEditor /> 
          </AuthGuard>
         },
        { path: 'add-quiz', element: 
          <AuthGuard requiredRole={[1,2]}>
            <QuizBuilder /> 
          </AuthGuard>
         },
        { path: 'forms', element: (
          <AuthGuard requiredRole={[1,2]}>
            <Forms /> 
          </AuthGuard>
        ) },
        { path: 'quiz', element: (
          <AuthGuard requiredRole={[1,2]}>
            <Quiz /> 
          </AuthGuard>
        ) },
        { path: 'url-shortner', element: (
          <AuthGuard requiredRole={[1,2,3]}>
            <UrlShortner /> 
          </AuthGuard>
        ) },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '403',
      element: <Page403 />,
    },
    {
      path: 'success',
      element: <Success />,
    },
    {
      path: '/schema/form/:id',
      element: (
        <AuthGuard requiredRole={['any']} page={'FormRender'}>
          <FormRender />
        </AuthGuard>
      ),
    },
    {
      path: '/schema/quiz/:id',
      element: (
        <AuthGuard requiredRole={['any']} page={'QuizRender'}>
          <QuizRender />
        </AuthGuard>
      ),
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
