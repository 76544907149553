import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  Divider,
  Modal,
  Grid,
} from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import axios from 'axios';
import Swal from 'sweetalert2';
import { url } from '../utils/constants';

function QuizEditor() {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [timer, setTimer] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [questionDescription, setQuestionDescription] = useState('');
  const [currentAnswers, setCurrentAnswers] = useState([{ text: '', isCorrect: false }]);
  const [openPreview, setOpenPreview] = useState(false);

  const handleAddQuestion = () => {
    if (currentAnswers.length < 2) {
      alert('Cada pergunta deve ter pelo menos duas respostas.');
      return;
    }

    if (!currentAnswers.some(answer => answer.isCorrect)) {
      // console.log(answer.isCorrect)
      alert('Cada pergunta deve ter uma resposta correta marcada.');
      return;
    }

    // Add the question to the state
    setQuestions([...questions, { question: currentQuestion, description: questionDescription, answers: currentAnswers }]);
    setCurrentQuestion('');
    setQuestionDescription('');
    setCurrentAnswers([{ text: '', isCorrect: false }]);
  };

  const handleAIHelp = async () => {
    try {
      const response = await axios.post('http://172.20.184.25:18000/generate', {
        prompt: `Given the question: "${currentQuestion}" and description: "${questionDescription}", generate 3 answer options and a description.`
      });
  
      const suggestions = response.data.response.split('\n');
      const generatedAnswers = suggestions.slice(0, 3).map((text, index) => ({ text, isCorrect: index === 0 }));
      
      setCurrentAnswers(generatedAnswers);
      setQuestionDescription(suggestions[3] || ""); // Use the fourth line as the description if present
    } catch (error) {
      console.error('Error fetching AI help:', error);
      Swal.fire({
        title: 'Erro ao gerar opções',
        text: 'Ocorreu um erro ao buscar sugestões da IA.',
        icon: 'error',
      });
    }
  };
  
  const handleAddAnswer = () => {
    setCurrentAnswers([...currentAnswers, { text: '', isCorrect: false }]);
  };

  const handleAnswerChange = (index, newText) => {
    const updatedAnswers = currentAnswers.map((answer, i) => 
      i === index ? { ...answer, text: newText } : answer
    );
    setCurrentAnswers(updatedAnswers);
  };

  const handleCorrectAnswerChange = (index) => {
    const updatedAnswers = currentAnswers.map((answer, i) => ({
      ...answer,
      isCorrect: i === index,
    }));
    setCurrentAnswers(updatedAnswers);
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const handleSaveQuiz = async () => {
    if (!title.trim()) {
      alert('O título do quiz não pode estar vazio.');
      return;
    }
    if (questions.length === 0) {
      alert('O quiz deve ter pelo menos uma pergunta.');
      return;
    }
    for (const question of questions) {
      if (!question.question.trim()) {
        alert('Cada pergunta precisa de um título.');
        return;
      }
      // if (!question.description.trim()) {
      //   alert('Cada pergunta precisa de uma descrição.');
      //   return;
      // }
    }

    const formData = {
      json_content: { ...questions },
      title: title,
      timer: timer,
      status: false,
      is_public: false,
    };

    const token = localStorage.getItem('token');

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(`${url}/new-quiz`, formData, { headers: { ...headers } });

      if (response.status === 201) {
        await Swal.fire({
          title: 'Quiz criado com sucesso',
          icon: 'success',
          text: 'Você será redirecionado para a página de formulários',
          timer: 4000,
        });

        navigate('/dashboard/forms');
      }
    } catch (error) {
      await Swal.fire({
        title: 'Falha ao processar solicitação',
        icon: 'error',
        text: 'Ocorreu um erro ao enviar seu pedido, tente novamente ou contacte o administrador do sistema',
        timer: 4000,
      });
    }

    console.log('Salvando Quiz:', { title, timer, questions });
  };

  const togglePreview = () => {
    setOpenPreview(!openPreview);
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" gutterBottom>Criar Novo Quiz</Typography>

      <Button variant="outlined" color="info" onClick={togglePreview} sx={{ mb: 3 }}>
        Pré-visualizar Quiz
      </Button>

      <Box mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              label="Título do Quiz"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Tempo (minutos)"
              variant="outlined"
              fullWidth
              type="number"
              inputProps={{ min: 0 }}
              value={timer}
              onChange={(e) => setTimer(Number(e.target.value))}
              margin="normal"
              helperText="0 para ilimitado"
            />
          </Grid>
        </Grid>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>Adicionar Pergunta</Typography>
        <TextField
          label="Título da Pergunta"
          variant="outlined"
          fullWidth
          value={currentQuestion}
          onChange={(e) => setCurrentQuestion(e.target.value)}
          margin="normal"
        />
        
        <Box mb={2}>
          <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>Descrição da Pergunta (Markdown Suportado)</Typography>
          <MDEditor value={questionDescription} onChange={setQuestionDescription} />
        </Box>

        <Button onClick={handleAIHelp} variant="outlined" sx={{ mt: 2 }}>
          Usar IA para criar opções e descrição
        </Button>
      </Box>

      <Box mb={4}>
        <Typography variant="h6">Respostas</Typography>
        {currentAnswers.map((answer, index) => (
          <Box key={index} display="flex" alignItems="center" mb={1}>
            <TextField
              label={`Resposta ${index + 1}`}
              variant="outlined"
              fullWidth
              value={answer.text}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              margin="dense"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={answer.isCorrect}
                  onChange={() => handleCorrectAnswerChange(index)}
                />
              }
              label="Correta"
              sx={{ ml: 2 }}
            />
          </Box>
        ))}
        
        <Button onClick={handleAddAnswer} variant="outlined" sx={{ mt: 2 }}>
          Adicionar Resposta
        </Button>
      </Box>

      <Divider sx={{ my: 3 }} />

      <Button
        onClick={handleAddQuestion}
        variant="contained"
        color="primary"
        sx={{ mt: 3, mb: 3 }}
      >
        Adicionar Pergunta ao Quiz
      </Button>

      <Divider sx={{ my: 3 }} />

      <Button
        onClick={handleSaveQuiz}
        variant="contained"
        color="success"
        sx={{ mt: 3 }}
      >
        Salvar Quiz
      </Button>

      <Modal open={openPreview} onClose={togglePreview} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ width: '80%', bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24, p: 4 }}>
          <Typography variant="h5" gutterBottom>Pré-visualização do Quiz</Typography>
          <Typography variant="h6">{title}</Typography>
          {questions.map((q, index) => (
            <Box key={index} mb={2}>
              <Typography variant="body1">{index + 1}. {q.question}</Typography>
              <Typography variant="body2" color="text.secondary">{q.description}</Typography>
              <Typography variant="body2" color="text.secondary">Opções:</Typography>
              {q.answers.map((answer, idx) => (
                <Typography key={idx} variant="body2">- {answer.text} {answer.isCorrect ? '(Correta)' : ''}</Typography>
              ))}
            </Box>
          ))}
          <Button variant="outlined" onClick={togglePreview}>Fechar Pré-visualização</Button>
        </Box>
      </Modal>
    </Container>
  );
}

export default QuizEditor;
