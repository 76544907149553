import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  Divider
} from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { url } from '../utils/constants';

function QuizEdit() {
  const { id } = useParams(); // Obtém o quid do quiz da URL
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [timer, setTimer] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [isPublic, setIsPublic] = useState(false); // Adicionado para consistência
  const [status, setStatus] = useState(true); // Adicionado para consistência

  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${url}/api/quiz/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const quizData = response.data;
        setTitle(quizData.title);
        setTimer(quizData.timer || 0);
        setIsPublic(quizData.is_public);
        setStatus(quizData.status);
        

        // Verifica se json_content é string e converte para objeto
        const jsonContent = typeof quizData.json_content === 'string' 
          ? JSON.parse(quizData.json_content) 
          : quizData.json_content;

        // Assume que json_content é um array de perguntas ou um objeto com perguntas
        setQuestions(Array.isArray(jsonContent) ? jsonContent : Object.values(jsonContent));
      } catch (error) {
        console.error('Erro ao carregar o quiz:', error);
        Swal.fire({ title: 'Erro ao carregar o quiz', icon: 'error', text: 'Tente novamente.' });
      }
    };
    
    
    fetchQuiz();
  }, [id]);

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleAnswerChange = (qIndex, aIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].answers[aIndex].text = value;
    setQuestions(updatedQuestions);
  };

  const handleCorrectAnswerChange = (qIndex, aIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].answers = updatedQuestions[qIndex].answers.map((answer, i) => ({
      ...answer,
      isCorrect: i === aIndex,
    }));
    setQuestions(updatedQuestions);
  };

  const handleSaveQuiz = async () => {
    const token = localStorage.getItem('token');
    const formData = {
      title,
      timer,
      status, // Incluído para consistência
      is_public: isPublic, // Incluído para consistência
      json_content: questions, // Envia como array diretamente
    };

    try {
      await axios.put(`${url}/api/quiz/${id}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      Swal.fire({ title: 'Quiz atualizado com sucesso!', icon: 'success', timer: 3000 });
      navigate('/dashboard/quiz');
    } catch (error) {
      console.error('Erro ao atualizar o quiz:', error);
      Swal.fire({ title: 'Erro ao atualizar o quiz', icon: 'error', text: error.response?.data?.error || 'Tente novamente.' });
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>Editar Quiz</Typography>
      <TextField
        label="Título do Quiz"
        fullWidth
        variant="outlined"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        margin="normal"
      />
      <TextField
        label="Tempo (minutos)"
        type="number"
        fullWidth
        variant="outlined"
        value={timer}
        onChange={(e) => setTimer(Number(e.target.value))}
        margin="normal"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isPublic}
            onChange={(e) => setIsPublic(e.target.checked)}
          />
        }
        label="Público"
        sx={{ mb: 2 }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={status}
            onChange={(e) => setStatus(e.target.checked)}
          />
        }
        label="Ativo"
        sx={{ mb: 2 }}
      />
      <Divider sx={{ my: 3 }} />
      {questions.map((question, qIndex) => (
        <Box key={qIndex} mb={3}>
          <TextField
            label={`Pergunta ${qIndex + 1}`}
            fullWidth
            variant="outlined"
            value={question.question || ''}
            onChange={(e) => handleQuestionChange(qIndex, 'question', e.target.value)}
            margin="normal"
          />
          {question.answers.map((answer, aIndex) => (
            <Box key={aIndex} display="flex" alignItems="center" mb={1}>
              <TextField
                label={`Resposta ${aIndex + 1}`}
                variant="outlined"
                fullWidth
                value={answer.text || ''}
                onChange={(e) => handleAnswerChange(qIndex, aIndex, e.target.value)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={answer.isCorrect || false}
                    onChange={() => handleCorrectAnswerChange(qIndex, aIndex)}
                  />
                }
                label="Correta"
                sx={{ ml: 2 }}
              />
            </Box>
          ))}
        </Box>
      ))}
      <Button onClick={handleSaveQuiz} variant="contained" color="primary">Salvar Alterações</Button>
    </Container>
  );
}

export default QuizEdit;