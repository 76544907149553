import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import Swal from "sweetalert2";
import { useNavigate, useParams, Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Container, Typography, Paper, Grid, Box, Divider, Card, CardContent, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { Pie } from 'react-chartjs-2';
import Select from 'react-select';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment';
import 'moment/locale/pt-br'; // Import the Portuguese locale
import 'chart.js/auto';
import { url } from '../utils/constants';
import * as XLSX from "xlsx";

moment.locale('pt-br'); // Set locale to Portuguese


const exportToExcel = (userAnswers) => {
  if (!userAnswers || userAnswers.length === 0) {
    console.warn("No data to export");
    return;
  }

  const rows = userAnswers.map((answer) => {
    const row = {};
    answer.json_content.forEach((section) => {
      section.fields.forEach((field) => {
        row[field.label] = field.value;
      });
    });
    return row;
  });

  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "FormData");

  XLSX.writeFile(workbook, "Relatorio-formularios-.xlsx");
};

const NoAnswersPlaceholder = () => (
  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%" textAlign="center">
    <img src='/assets/illustrations/attract-users.svg' alt="Sem Respostas" style={{ maxWidth: '300px', marginBottom: '20px' }} />
    <Typography variant="h6" color="textSecondary" gutterBottom>
      Ainda não há respostas disponíveis.
    </Typography>
    <Typography variant="body2" color="textSecondary">
      Respostas aparecerão aqui assim que forem adicionadas.
    </Typography>
  </Box>
);

const CombinedAnswerCard = ({ answers }) => {
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [isSortedAscending, setIsSortedAscending] = useState(true);
  const [isPrinting, setIsPrinting] = useState(false)

  const handleAccordionChange = (index) => (_, isExpanded) => {
    setExpandedIndex(isExpanded ? index : false);
  };

  const exportToPdf = async (index) => {
    try {
      setIsPrinting(true);
  
      const element = document.getElementById(`pdf-content-${index}`);
      const buttons = element.querySelectorAll("button"); // Select buttons to hide
  
      // Hide buttons
      buttons.forEach(button => button.style.display = "none");
  
      // Allow some time for the buttons to hide
      setTimeout(async () => {
        const canvas = await html2canvas(element);
        const imageData = canvas.toDataURL("image/png");
  
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: "a4",
        });
  
        const pageWidth = pdf.internal.pageSize.getWidth();
        const imageWidth = canvas.width > pageWidth ? pageWidth : canvas.width;
        const imageHeight = (canvas.height * imageWidth) / canvas.width;
  
        pdf.addImage(imageData, "PNG", 0, 0, imageWidth, imageHeight);
        pdf.save("export.pdf");
  
        // Show buttons again after capture
        buttons.forEach(button => button.style.display = "");
  
        setIsPrinting(false);
      }, 100); // Short delay to ensure buttons are hidden
  
    } catch (e) {
      console.log(e);
      setIsPrinting(false);
    }
  };


  const handleDelete = (answerId) => {
    Swal.fire({
      title: 'Tem certeza?',
      text: "Você não poderá reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, deletar!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = localStorage.getItem('token');
          await axios.delete(`${url}/api/answer/${answerId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          Swal.fire('Deletado!', 'A resposta foi deletada com sucesso.', 'success');
        } catch (error) {
          Swal.fire('Erro!', 'Não foi possível deletar a resposta.', 'error');
        }
      }
    });
  };

  const formatDate = (dateString) => moment(dateString).fromNow();


  if (answers.length === 0) {
    return (
      <Grid item xs={12} sx={{mt:2}}>
        <Card style={{ height: '100%' }}>
          <CardContent>
            <NoAnswersPlaceholder />
          </CardContent>
        </Card>
      </Grid>
    );
  }

  // Calculate total scores and sort answers
  const sortedAnswers = [...answers].sort((a, b) => {
    const totalScoreA = a.json_content.reduce((acc, section) => {
      return acc + section.fields.reduce((sectionAcc, field) => sectionAcc + (field.score || 0), 0);
    }, 0);

    const totalScoreB = b.json_content.reduce((acc, section) => {
      return acc + section.fields.reduce((sectionAcc, field) => sectionAcc + (field.score || 0), 0);
    }, 0);

    return isSortedAscending ? totalScoreA - totalScoreB : totalScoreB - totalScoreA;
  });

  return (
    <Grid item xs={12}>
      <Card style={{ height: '100%' }}>
        <CardContent>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" color="primary" gutterBottom>
              Respostas
            </Typography>
            <Button
              onClick={() => setIsSortedAscending(!isSortedAscending)}
              style={{ minWidth: '40px', marginLeft: '16px' }}
            >
              {isSortedAscending ? '🔼' : '🔽'} {/* Toggle icon for sorting */}
            </Button>
          </Box>
          <Divider />
          {sortedAnswers.map((answer, index) => {
            // Calculate the total score for this answer
            const totalScore = answer.json_content.reduce((acc, section) => {
              return acc + section.fields.reduce((sectionAcc, field) => sectionAcc + (field.score || 0), 0);
            }, 0);

            return (
              <Accordion
                key={index}
                expanded={expandedIndex === index}
                onChange={handleAccordionChange(index)}
                id={`pdf-content-${index}`}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <Typography variant="subtitle1" color="textSecondary">
                      Respondido por usuário: {answer.User.fullname} {formatDate(answer.createdAt)}
                    </Typography>
                    {answer.file !== null && 
                    <Typography><a href={answer.file}><AttachmentIcon /></a></Typography>
                    }
                    <Typography variant="body1" color="primary">
                      Pontuação Total: {totalScore}
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {answer.json_content.map((section, secIndex) => (
                    <Box key={secIndex} mt={2}>
                      <Typography variant="h6" color="primary">
                        {section.section}
                      </Typography>
                      <Divider />
                      {section.fields.map((field, fieldIndex) => (
                        <Box key={fieldIndex} mt={2}>
                          <Typography variant="subtitle1" color="textSecondary">
                            {field.label}:
                          </Typography>
                          <Typography variant="body1">{field.value}</Typography>
                          {field.justification && (
                            <Typography variant="body2" color="textSecondary">
                              Justificativa: {field.justification}
                            </Typography>
                          )}
                          {field.score !== 0 && (
                            <Typography variant="body2" color="textSecondary">
                              Score: {field.score}
                            </Typography>
                          )}
                        </Box>
                      ))}
                    </Box>
                  ))}
                  <Box display="flex" justifyContent="flex-end" mt={2}>
    
  
                      <Button
                      variant="outlined"
                      color="secondary"
                      style={{display: isPrinting ? 'none' : 'block'}}
                      onClick={() => handleDelete(answer.id)}
                    >
                      Deletar Resposta
                    </Button>
                    <Button
                    variant="contained"
                    color="primary"
        
                    onClick={() => exportToPdf(index)}
                    style={{ marginLeft: '10px',display: isPrinting ? 'none' : 'block' }}
                  >
                    Exportar para PDF
                  </Button>

                  </Box>
                  <Divider style={{ margin: '15px 0' }} />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </CardContent>
      </Card>
    </Grid>
  );
};



const SummaryGraph = ({ answers }) => {
  const questionCounts = {};

  answers.forEach(answer => {
    answer.json_content.forEach(section => {
      section.fields.forEach(field => {
        if (field.value && !field.inputType.includes('text') || !field.inputType == 'number' || !field.inputType == 'file') {
          if (!questionCounts[field.label]) questionCounts[field.label] = {};
          if (!questionCounts[field.label][field.value]) questionCounts[field.label][field.value] = 0;
          questionCounts[field.label][field.value]++;
        }
      });
    });
  });

  return (
    <Grid container spacing={3}>
      {Object.entries(questionCounts).map(([question, optionsCount], index) => {
        const data = {
          labels: Object.keys(optionsCount),
          datasets: [
            {
              data: Object.values(optionsCount),
              backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0'],
            },
          ],
        };

        return (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
              <Typography variant="h6" color="primary" gutterBottom>
                Resumo de Respostas para "{question}"
              </Typography>
              <Divider />
              <Pie data={data} />
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

const ReportPage = ({ userAnswers }) => {
  console.log(userAnswers)

  return (
    <Container maxWidth="lg" sx={{mt:2}}>
      {userAnswers.length > 0 && <Typography variant="h4" align="center" gutterBottom sx={{ mb: 5 }}>
        Resumo
      </Typography>}

      <Button
        variant="contained"
        color="primary"
        onClick={() => exportToExcel(userAnswers)}
        style={{ marginBottom: "20px" }}
      >
        Exportar para Excel
      </Button>

      <SummaryGraph answers={userAnswers} />

      <Grid container spacing={3}>
        <CombinedAnswerCard answers={userAnswers} />
      </Grid>
    </Container>
  );
};


const App = () => {
  const { id } = useParams(); 
  const [formData, setFormData] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [filteredName, setFilteredName] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${url}/api/form/${id}/answers`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const formArray = Array.isArray(response.data) ? response.data : Object.values(response.data);
        setFormData(formArray);

        const names = formArray.map((answer) => answer.User.fullname);
        setUserOptions([...new Set(names)].map(name => ({ value: name, label: name })));
      } catch (error) {
        console.error('Error fetching forms:', error);
      }
    };

    fetchForm();
  }, [id]);


  // Memoize filtered data to recompute only when dependencies change
  const filteredData = useMemo(() => 
    filteredName ? formData.filter(answer => answer.User.fullname == filteredName.value) : formData
  , [filteredName, formData]);

  useEffect(() => {
    console.log("Filtered data updated:", filteredData);
  }, [filteredData]);


  return (
    <Container>
      <Link to="/dashboard/forms" style={{ textDecoration: 'none' }}>
        <Button variant="outlined" color="primary" startIcon={<ArrowBackIcon />} style={{ marginBottom: '20px' }}>
          Voltar
        </Button>
      </Link>

      {/* Dropdown for selecting operator */}
      <Select
        options={userOptions}
        placeholder="Filtrar por operador"
        isClearable
        onChange={setFilteredName}
        style={{ marginBottom: '20px', width: '300px' }}
      />

      <ReportPage userAnswers={filteredData} />
    </Container>
  );
};

export default App;
