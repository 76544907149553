const url = 'https://forms-api.redeatel.com.br'
const roles = {
    "_1": {'name': 'administrador'},
    "_2": {'name': 'editor'},
    "_3": {'name': 'visualizador'},
}

export {
    url,
    roles
}