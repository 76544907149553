import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import Swal from 'sweetalert2';
import axios from 'axios';
import { url } from '../utils/constants';

const EditUserProfile = () => {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    status: true,
    password: ''
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchForm = async () => {
        try {
          const token = localStorage.getItem('token');
  
          const response = await axios.get(`${url}/api/users/profile`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          // setLoading(false);
          setFormData({ ...response.data, password: '' });
          
        } catch (error) {
          // navigate('/404');
          
          console.error('Error fetching forms:', error);
        }finally{
          setLoading(false);
        }
      };
  
      fetchForm();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Send the updated user data to the API
    try{
      const token = localStorage.getItem('token');

      const response = await axios.put(`${url}/api/users/profile`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      Swal.fire({
        title: "Perfil alterado com sucesso",
        icon: "success",
        timer: 2000
      })
  
    }catch(e){
      Swal.fire({
        title: "Ocorreu um erro ao alterar os dados",
        icon: "error",
        timer: 2000
      })
      console.log(e)
    }

  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Editar Perfil
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          {/* Nome Completo */}
          <TextField
            fullWidth
            label="Nome Completo"
            value={formData.fullname}
            onChange={(e) => setFormData({ ...formData, fullname: e.target.value })}
            margin="normal"
            variant="outlined"
            required
          />

          {/* E-mail */}
          <TextField
            fullWidth
            label="E-mail"
            type="email"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            margin="normal"
            variant="outlined"
            disabled
          />


          <TextField
            fullWidth
            label="Senha"
            type="password"
            value={formData.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            margin="normal"
            variant="outlined"
            // required
            placeholder="Deixe em branco caso não queira atualizar"
          />

          {/* Botão de envio */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Atualizar Perfil
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default EditUserProfile;
