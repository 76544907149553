// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('icons8-home'),
  },
  {
    title: 'usuários',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'formulários',
    path: '/dashboard/forms',
    icon: icon('form'),
  },
  {
    title: 'quiz',
    path: '/dashboard/quiz',
    icon: icon('quiz-icon'),
  },
  {
    title: 'Relatórios para todos',
    path: '/dashboard/reports',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Encurtador de URL',
    path: '/dashboard/url-shortner',
    icon: icon('icons8-url'),
  },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
