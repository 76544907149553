import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  InputLabel
} from '@mui/material';
import Swal from 'sweetalert2';
import axios from 'axios';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { url, roles } from '../utils/constants';

// Table headers
const TABLE_HEAD = [
  { id: 'name', label: 'Nome', alignRight: false },
  { id: 'email', label: 'E-mail', alignRight: false },
  { id: 'role', label: 'Grupo', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// Main component
export default function UserPage() {
  const [openMenu, setOpenMenu] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [passwordData, setPasswordData] = useState({ newPassword: '', confirmPassword: '' });
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${url}/api/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Fetch users from API
  useEffect(() => {

    fetchUsers();
  }, []);

  // Sort and filter functions
  const applySortFilter = (array, comparator, query) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      return order !== 0 ? order : a[1] - b[1];
    });
    return query
      ? stabilizedThis
          .filter((user) => user[0].fullname.toLowerCase().includes(query.toLowerCase()))
          .map((el) => el[0])
      : stabilizedThis.map((el) => el[0]);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && !!filterName;

  // Handlers for menu and pagination
  const handleOpenMenu = (event, user) => {
    setSelectedUser(user);
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => setOpenMenu(null);

  const handleSelectAllClick = (event) => {
    const newSelecteds = event.target.checked ? users.map((n) => n.fullname) : [];
    setSelectedUsers(newSelecteds);
  };

  const handleClick = (event, name) => {
    const newSelected = selectedUsers.includes(name)
      ? selectedUsers.filter((user) => user !== name)
      : [...selectedUsers, name];
    setSelectedUsers(newSelected);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Edit user handler
  const handleEditUser = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${url}/api/users/${selectedUser.id}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      await Swal.fire({
        title: "Dados alterados com sucesso",
        icon: "success",
        timer: 2000,
        zIndex: 9999
      })

      setEditDialogOpen(false);
      // Refresh user list after editing
      await fetchUsers();
    } catch (error) {
      console.log(formData)
      console.error('Error updating user:', error);
    }
  };

  // Disable user handler
  const handleDisableUser = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${url}/api/users/${selectedUser.id}/disable`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      handleCloseMenu();
      // Refresh user list after disabling
      await fetchUsers();
    } catch (error) {
      console.error('Error disabling user:', error);
    }
  };

  // Change password handler
  const handleChangePassword = async () => {
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      alert('Passwords do not match!');
      return;
    }
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${url}/api/users/${selectedUser.id}/password`, { password: passwordData.newPassword }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setChangePasswordDialogOpen(false);
      // Clear password data after change
      setPasswordData({ newPassword: '', confirmPassword: '' });
      // Refresh user list after changing password
      await fetchUsers();
    } catch (error) {
      console.error('Error changing password:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Usuários | Atel Forms</title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Usuários
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => document.location.href = '/dashboard/new-user'}>
            Novo usuário
          </Button>
        </Stack>
        <Card>
          <UserListToolbar
            numSelected={selectedUsers.length}
            filterName={filterName}
            onFilterName={(event) => setFilterName(event.target.value)}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  numSelected={selectedUsers.length}
                  onRequestSort={(event, property) => {
                    const isAsc = orderBy === property && order === 'asc';
                    setOrder(isAsc ? 'desc' : 'asc');
                    setOrderBy(property);
                  }}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, fullname, email, role_id: roleId, status } = row;
                    const isSelected = selectedUsers.indexOf(fullname) !== -1;

                    return (
                      <TableRow hover key={id} selected={isSelected} role="checkbox">
                        <TableCell padding="checkbox">
                          <Checkbox checked={isSelected} onChange={(event) => handleClick(event, fullname)} />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>{fullname}</Typography>
                        </TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{roles[`_${roleId}`]?.name || 'Unknown'}</TableCell>
                        <TableCell align="left">
                          <Label color={!status ? 'error' : 'success'}>
                            {!status ? 'Banido' : 'Ativo'}
                          </Label>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton onClick={(event) => handleOpenMenu(event, row)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {isNotFound && (
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Typography variant="subtitle1" paragraph>
                          Não encontrado
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* User Menu for Edit, Disable, and Change Password */}
      <Popover
        open={Boolean(openMenu)}
        anchorEl={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={() => {
          setFormData(selectedUser); // Pre-fill form data for editing
          setEditDialogOpen(true);
          handleCloseMenu();
        }}>
          Editar
        </MenuItem>
        <MenuItem onClick={() => {
          handleDisableUser();
          handleCloseMenu();
        }}>
          Desabilitar/Habilitar
        </MenuItem>
        <MenuItem onClick={() => {
          setChangePasswordDialogOpen(true);
          handleCloseMenu();
        }}>
          Alterar Senha
        </MenuItem>
      </Popover>

      {/* Edit User Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Editar Usuário</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nome"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.fullname || ''}
            onChange={(e) => setFormData({ ...formData, fullname: e.target.value })}
          />
          <Select
          sx={{mb:2, mt:2}}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.role_id || ''}
            label="Role"
            onChange={(e) => setFormData({ ...formData, role_id: e.target.value })}
          >
            <MenuItem selected value={1}>Administrador</MenuItem>
            <MenuItem value={2}>Editor</MenuItem>
            <MenuItem value={3}>Visualizador</MenuItem>
          </Select>
          <TextField
            margin="dense"
            label="E-mail"
            type="email"
            fullWidth
            variant="outlined"
            value={formData.email || ''}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
          {/* Add more fields as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancelar</Button>
          <Button onClick={handleEditUser}>Salvar</Button>
        </DialogActions>
      </Dialog>

      {/* Change Password Dialog */}
      <Dialog open={changePasswordDialogOpen} onClose={() => setChangePasswordDialogOpen(false)}>
        <DialogTitle>Alterar Senha</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nova Senha"
            type="password"
            fullWidth
            variant="outlined"
            value={passwordData.newPassword}
            onChange={(e) => setPasswordData({ ...passwordData, newPassword: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Confirmar Senha"
            type="password"
            fullWidth
            variant="outlined"
            value={passwordData.confirmPassword}
            onChange={(e) => setPasswordData({ ...passwordData, confirmPassword: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setChangePasswordDialogOpen(false)}>Cancelar</Button>
          <Button onClick={handleChangePassword}>Salvar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
