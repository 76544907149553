import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { validateToken, validateRole } from '../authService';

const AuthGuard = ({ children, requiredRole, nextUrl = null }) => {
  const [isAuthorized, setIsAuthorized] = useState(null);
  const [isExpired, setIsExpired] = useState(null);
  const token = localStorage.getItem('token');

  nextUrl = nextUrl === null ? window.location.href : nextUrl; 

  useEffect(() => {

    const checkAuthorization = async () => {
      if (!token) {
        // Capture the current URL
        document.cookie = `nextUrl=${encodeURIComponent(nextUrl)}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`; // Set cookie
        document.location.href = '/login'
      }

      const { isAuthorized: tokenIsValid, isExpired: tokenIsExpired, role } = await validateToken(token);
      setIsExpired(tokenIsExpired); // Set the expiration state

      // Validate role if token is valid
      if (tokenIsValid) {
        const roleIsValid = validateRole(role, requiredRole);
        setIsAuthorized(roleIsValid);
      } else {
        setIsAuthorized(tokenIsValid);
      }
    };

    checkAuthorization();
  }, [token, requiredRole]);

  if (isAuthorized === null || isExpired === null) {
    return <div>Loading...</div>;
  }

  if (isExpired) {
    document.cookie = `nextUrl=${encodeURIComponent(nextUrl)}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`; // Set cookie

    return <Navigate to='/login' />;
  }

  if (!isAuthorized) {
    document.cookie = `nextUrl=${encodeURIComponent(nextUrl)}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT`; // Set cookie

    return <Navigate to='/403' />;
  }

  return children;
};

export default AuthGuard;
