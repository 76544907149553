import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
// import SweetAlert2 from 'react-sweetalert2';
import Swal from 'sweetalert2';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page403() {
  return (
    <>
      <Helmet>
        <title>Acesso não autorizado | Atel Forms</title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Ops, volte de onde veio!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Parece que você não tem acesso a essa página, mãos na cabeça!
          </Typography>

          <Box
            component="img"
            src="/assets/illustrations/illustration_403.svg"
            sx={{ height: 420, mx: 'auto', my: { xs: 5, sm: 10 } }}
          />
{/* 
          <Button to="/" size="large" variant="contained" component={RouterLink}>
            Pagar fiança
          </Button> */}
          <Button onClick={async () => {
            await Swal.fire({
                icon: 'success',
                title: 'Fiança paga com sucesso',
                text: 'Você está livre para ir pra casa',
                timer: 4000
            })

            document.location.href="/"
          }} size="large" variant="contained">
            Pagar fiança
          </Button>
        </StyledContent>
      </Container>
    </>
  );
}
