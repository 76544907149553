import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Box, Container, Typography, Divider, Chip, Stack, IconButton, Menu, MenuItem } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet-async';
import { Public, Lock, MoreVert } from '@mui/icons-material';
import Iconify from '../components/iconify';
import { url } from '../utils/constants';

function Quiz() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuRowId, setMenuRowId] = useState(null);
  
  const handleMenuClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setMenuRowId(rowId);
  };

  const performAction = async (quizId, action) => {
    try {

      const token = localStorage.getItem('token');

      const headers = {
        Authorization: `Bearer ${token}`, // Send the token in the header
      }

      const response = await axios.post(`${url}/api/quiz/action`, {quiz_id: quizId, action: action}, {headers: {...headers}});

      if (response) {
        // Optionally, refresh the form list or update the state
        Swal.fire({
          title: 'Ação realizada com sucesso',
          icon: 'success'
        })

        fetchQuiz()
        console.log('Action performed successfully');
      } else {
        console.error('Failed to perform action');
      }
    } catch (error) {
      console.error('Error performing action:', error);
    }
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuRowId(null);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'quizname',
      headerName: 'Quiz',
      width: 360,
      renderCell: (params) => (
        <Link to={`/dashboard/quiz/${params.row.id}`} style={{ textDecoration: 'none', color: 'blue' }}>
          {params.row.title}
        </Link>
      ),
    },
    {
      field: 'fullname',
      headerName: 'Criado por',
      width: 180,
      renderCell: (params) => (
        <span>{params.row.User.fullname}</span>
      ),
    },
    { 
      field: 'sent', 
      headerName: 'Envios realizados', 
      type: 'number', 
      width: 160, 
      renderCell: (params) => (
        <Chip
          label={params.row.answerCount}
          color={'default'}
          variant="outlined"
        />
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      renderCell: (params) => (
        <Chip
          label={params.row.status ? 'ativo' : 'desativado'}
          color={params.row.status ? 'success' : 'default'}
          variant="outlined"
        />
      ),
    },
    {
      field: 'is_public',
      headerName: 'Público',
      description: 'Público aberto não necessita realizar login',
      renderCell: (params) => (
        params.value ? <Public /> : <Lock />
      ),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      sortable: false,
      width: 180,
      renderCell: (params) => (
        <>
          <IconButton onClick={(event) => handleMenuClick(event, params.row.id)}>
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && menuRowId === params.row.id}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => { /* Implement view functionality */ handleMenuClose(); }}>
              <a href={`/schema/quiz/${params.row.quid}`} target='_blank' rel='noreferrer' style={{ textDecoration: 'none', color: 'inherit' }}>
                Visualizar
              </a>
            </MenuItem>
            {params.row.status ? (
              <MenuItem onClick={() => { /* Implement disable functionality */ performAction(params.row.id, 'disable') }}>Desabilitar</MenuItem>
            ) : (
              <MenuItem onClick={() => { /* Implement enable functionality */ performAction(params.row.id, 'enable') }}>Habilitar</MenuItem>
            )}
            <MenuItem onClick={() => { /* Implement public/private functionality */ performAction(params.row.id, params.row.is_public ? 'makePrivate' : 'makePublic') }}>
              {params.row.is_public ? 'Tornar Privado' : 'Tornar Público'}
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  const [quiz, setQuiz] = useState([]);

  const fetchQuiz = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${url}/api/quiz`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setQuiz(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching forms:', error);
    }
  };

  useEffect(() => {
    fetchQuiz();
  }, []);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title> Quiz | Atel Forms </title>
      </Helmet>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Quiz criados
        </Typography>
        <Button
          onClick={() => { document.location.href = '/dashboard/add-quiz'; }}
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Novo Quiz
        </Button>
      </Stack>

      <Paper sx={{ height: 330, width: '100%' }}>
        <DataGrid
          rows={quiz}
          columns={columns}
          initialState={{ pagination: { paginationModel: { page: 0, pageSize: 5 } } }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          sx={{ border: 0 }}
        />
      </Paper>
    </Container>
  );
}

export default Quiz;
