import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  Divider,
  Modal,
  Grid,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MDEditor from '@uiw/react-md-editor';
import axios from 'axios';
import Swal from 'sweetalert2';
import { url } from '../utils/constants';
import { filter } from 'lodash';
import { element } from 'prop-types';

function QuizEditor() {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [timer, setTimer] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [questionDescription, setQuestionDescription] = useState('');
  const [currentAnswers, setCurrentAnswers] = useState([{ text: '', isCorrect: false }]);
  const [openPreview, setOpenPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idCurrentQuestion, setIdCurrentQuestion] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');
  const [loadingQuery, setLoadingQuery] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  useEffect(() => {

    if (idCurrentQuestion <= 0 || idCurrentQuestion > questions.length) {
      setCurrentQuestion('');
      setQuestionDescription('');
      setCurrentAnswers([{ text: '', isCorrect: false }]);
      return
    }

    console.log(idCurrentQuestion)
    console.log(questions)
    const question = questions.filter(element => {
      if (element.idQuestion === idCurrentQuestion)
        return element
    })[0]
    console.log(question)
    setCurrentQuestion(question.question);
    setQuestionDescription(question.description);
    setCurrentAnswers(question.answers);
  }, [idCurrentQuestion])

  const handleAIQuery = async () => {
    setLoadingQuery(true);
    const url = 'https://api-inference.huggingface.co/models/mistralai/Mistral-7B-Instruct-v0.3';
    const token = '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'; // Replace with your actual token

    const txt = "responda a seguinte pergunta, TRADUZIDO EM PORTUGUÊS, seja direto e responda apenas o que foi perguntado, segue a dúvida: "

    try {
      const response = await axios.post(
        url,
        { inputs: txt + query },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setResponse(response.data[0]?.generated_text.replace(txt + query, '') || 'Sem resposta da IA.');
    } catch (error) {
      console.error('Erro ao enviar a dúvida para a IA:', error);
      setResponse('Ocorreu um erro ao processar sua dúvida. Tente novamente.');
    } finally {
      setLoadingQuery(false);
    }
  };


  const handleAddQuestion = () => {
    if (currentAnswers.length < 2) {
      alert('Cada pergunta deve ter pelo menos duas respostas.');
      return;
    }

    if (!currentAnswers.some(answer => answer.isCorrect)) {
      alert('Cada pergunta deve ter uma resposta correta marcada.');
      return;
    }
    if (idCurrentQuestion > 0) {
      
      console.log(idCurrentQuestion + "algo aqui")
      const updatedQuestions = questions.map(question => {
        if (question.idQuestion === idCurrentQuestion) {
          console.log("E aqui estou em: " + question.idQuestion);
          return {
            ...question,
            question: currentQuestion,
            description: questionDescription,
            answers: currentAnswers
          };
        }
        return question;
      });
      //O reset do formulário... Dá pra melhorar isso depois com Reducer
      setQuestions([...updatedQuestions])
      setIdCurrentQuestion(0)
      setCurrentQuestion('');
      setQuestionDescription('');
      setCurrentAnswers([{ text: '', isCorrect: false }]);
      return
    }

    setQuestions([...questions, { idQuestion: questions.length + 1, question: currentQuestion, description: questionDescription, answers: currentAnswers }]);
    setCurrentQuestion('');
    setQuestionDescription('');
    setCurrentAnswers([{ text: '', isCorrect: false }]);
  };

  const handleAIHelp = async () => {
    setLoading(true);  // Start loading
    const url = 'https://api-inference.huggingface.co/models/meta-llama/Llama-3.2-3B-Instruct';
    const token = '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';  // Replace with your actual token

    const txt = `Generate three answer options for the question: '${currentQuestion}'. Ensure answers are formatted as [], example [answer1, answer2, answer3] and avoid unnecessary space or verbose explanations. I just need the answers without further explanations.The output MUST be in English language`

    try {
      const response = await axios.post(
        url,
        {
          inputs: txt
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const generatedText = response.data[0]?.generated_text.replace('[answer1, answer2, answer3]', '') || '';
      generatedText = generatedText.replace(txt, '')

      const answersMatch = generatedText.match(/\[([^\]]+)\]/);

      if (!answersMatch || answersMatch.length < 2) {
        console.error('No valid answers found in the expected format.');
        return;
      }

      const answers = answersMatch[1].split(',').map(answer => answer.trim());

      const generatedAnswers = answers.slice(0, 3).map((text, index) => ({
        text,
        isCorrect: index === 0,
      }));

      setCurrentAnswers(generatedAnswers);
      setQuestionDescription('');

    } catch (error) {
      console.error('Error generating answers:', error);
    } finally {
      setLoading(false);  // End loading
    }
  };

  const handleAddAnswer = () => {
    setCurrentAnswers([...currentAnswers, { text: '', isCorrect: false }]);
  };

  const handleAnswerChange = (index, newText) => {
    const updatedAnswers = currentAnswers.map((answer, i) =>
      i === index ? { ...answer, text: newText } : answer
    );
    setCurrentAnswers(updatedAnswers);
  };

  const handleCorrectAnswerChange = (index) => {
    const updatedAnswers = currentAnswers.map((answer, i) => ({
      ...answer,
      isCorrect: i === index,
    }));
    setCurrentAnswers(updatedAnswers);
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const handleNavigateToQuestion = (type) => {
    if (questions.length === 0) return;

    setIdCurrentQuestion((prev) => {
      if (type === 'prev') {
        return prev > 1 ? prev - 1 : questions.length;
      } else {
        return prev < questions.length ? prev + 1 : 0;
      }
    });
  };






  const handleSaveQuiz = async () => {

    if (!title.trim()) {
      alert('O título do quiz não pode estar vazio.');
      return;
    }
    if (questions.length === 0) {
      alert('O quiz deve ter pelo menos uma pergunta.');
      return;
    }
    for (const question of questions) {
      if (!question.question.trim()) {
        alert('Cada pergunta precisa de um título.');
        return;
      }
    }
        
    const handleData = questions.map(element =>{
      return {
        question: element.question,
        description: element.description,
        answers: element.answers
      }
    })
    console.log(handleData)

    const formData = {
      json_content: { ...handleData },
      title: title,
      timer: timer,
      status: false,
      is_public: false,
    };

    const token = localStorage.getItem('token');

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(`${url}/new-quiz`, formData, { headers: { ...headers } });

      if (response.status === 201) {
        await Swal.fire({
          title: 'Quiz criado com sucesso',
          icon: 'success',
          text: 'Você será redirecionado para a página de formulários',
          timer: 4000,
        });

        navigate('/dashboard/quiz');
      }
    } catch (error) {
      await Swal.fire({
        title: 'Falha ao processar solicitação',
        icon: 'error',
        text: 'Ocorreu um erro ao enviar seu pedido, tente novamente ou contacte o administrador do sistema',
        timer: 4000,
      });
    }
  };

  const togglePreview = () => {
    setOpenPreview(!openPreview);
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" gutterBottom>Criar Novo Quiz</Typography>

      <Button variant="outlined" color="info" onClick={togglePreview} sx={{ mb: 3 }}>
        Pré-visualizar Quiz
      </Button>

      <Box mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              label="Título do Quiz"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Tempo (minutos)"
              variant="outlined"
              fullWidth
              type="number"
              inputProps={{ min: 0 }}
              value={timer}
              onChange={(e) => setTimer(Number(e.target.value))}
              margin="normal"
              helperText="0 para ilimitado"
            />
          </Grid>
        </Grid>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>Adicionar Pergunta</Typography>
        <TextField
          label="Título da Pergunta"
          variant="outlined"
          fullWidth
          value={currentQuestion}
          onChange={(e) => setCurrentQuestion(e.target.value)}
          margin="normal"
        />

        <Box mb={2}>
          <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>Descrição da Pergunta (Markdown Suportado)</Typography>
          <MDEditor value={questionDescription} onChange={setQuestionDescription} />
        </Box>

        <Box>
          <Button
            onClick={handleAIHelp}
            variant="outlined"
            sx={{ mt: 2, mr: 1 }}
            disabled={loading}  // Disable button when loading
          >
            {loading ? '...' : 'Usar IA para criar opções e descrição'}  {/* Show loading or normal text */}
            <Tooltip title="A IA nem sempre é assertiva, cheque as questões geradas antes de criar o quiz">
              <IconButton size="small" sx={{ ml: 1 }}>
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Button>


          <Button variant="outlined" onClick={handleOpenModal} sx={{ mt: 2 }} >
            Tirar dúvida com IA
            <Tooltip title="Use com moderação, a IA é sua ajudante mas precisa descansar.">
              <IconButton size="small" sx={{ ml: 1 }}>
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Button>
        </Box>

        {/* AI Query Modal */}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Box sx={{ width: '400px', bgcolor: 'background.paper', p: 3, borderRadius: 2, boxShadow: 24 }}>
            <Typography variant="h6" gutterBottom>
              Tirar dúvida com IA
            </Typography>
            <TextField
              label="Digite sua dúvida"
              fullWidth
              multiline
              rows={4}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              sx={{ mb: 2, mt: 1 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAIQuery}
              disabled={loadingQuery}
              fullWidth
            >
              {loadingQuery ? 'Enviando...' : 'Enviar'}
            </Button>
            {response && (
              <Typography variant="body1" sx={{ mt: 2 }}>
                <strong>Resposta da IA:</strong> {response}
              </Typography>
            )}
          </Box>
        </Modal>

      </Box>

      <Box mb={4}>
        <Typography variant="h6">Respostas</Typography>
        {currentAnswers.map((answer, index) => (
          <Box key={index} display="flex" alignItems="center" mb={1}>
            <TextField
              label={`Resposta ${index + 1}`}
              variant="outlined"
              fullWidth
              value={answer.text}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              margin="dense"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={answer.isCorrect}
                  onChange={() => handleCorrectAnswerChange(index)}
                />
              }
              label="Correta"
              sx={{ ml: 2 }}
            />
          </Box>
        ))}

        <Button onClick={handleAddAnswer} variant="outlined" sx={{ mt: 2 }}>
          Adicionar Resposta
        </Button>
      </Box>

      <Divider sx={{ my: 3 }} />

      <Button
        onClick={handleAddQuestion}
        variant="contained"
        color="primary"
        sx={{ mt: 3, mb: 3 }}
      >
        {idCurrentQuestion != 0 ? "Atualizar Pergunta" : "Adicionar Pergunta ao Quiz"}
      </Button>

      <Button
        onClick={() => { handleNavigateToQuestion('prev') }}
        variant=""
        color="primary"
        sx={{ mt: 3, mb: 3 }}
        disabled={questions.length === 0 || idCurrentQuestion === 1}
      >
        Questão anterior
      </Button>

      <Button
        onClick={() => { handleNavigateToQuestion('next') }}
        variant=""
        color="primary"
        sx={{ mt: 3, mb: 3 }}
        disabled={idCurrentQuestion === 0}
      >
        Próxima Questão
      </Button>


      <Divider sx={{ my: 3 }} />

      <Button
        onClick={handleSaveQuiz}
        variant="contained"
        color="success"
        sx={{ mt: 3 }}
      >
        Salvar Quiz
      </Button>

      <Modal
        open={openPreview}
        onClose={togglePreview}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ width: '80%', bgcolor: 'background.paper', borderRadius: 2, boxShadow: 24, p: 4 }}>
          <Typography variant="h5" gutterBottom>
            Pré-visualização do Quiz
          </Typography>

          {/* Display Title and Timer */}
          <Typography variant="h6" sx={{ mb: 2 }}>
            <strong>Título:</strong> {title}
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            <strong>Tempo:</strong> {timer ? `${timer} minutos` : 'Ilimitado'}
          </Typography>

          {/* Display Questions and Answers */}
          {questions.length > 0 ? (
            questions.map((question, idx) => (
              <Box key={idx} sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {idx + 1}. {question.question}
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  {question.description && (
                    <>
                      <strong>Descrição:</strong> <div dangerouslySetInnerHTML={{ __html: question.description }} />
                    </>
                  )}
                </Typography>

                <Typography variant="body2">
                  <strong>Respostas:</strong>
                </Typography>
                <Box sx={{ ml: 2, mt: 1 }}>
                  {question.answers.map((answer, answerIdx) => (
                    <Box key={answerIdx} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Typography variant="body2" sx={{ mr: 1 }}>
                        {answerIdx + 1}. {answer.text}
                      </Typography>
                      {answer.isCorrect && (
                        <Typography variant="body2" sx={{ color: 'green' }}>
                          (Correta)
                        </Typography>
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body1">Nenhuma pergunta adicionada.</Typography>
          )}
        </Box>
      </Modal>

    </Container>
  );
}

export default QuizEditor;
