// ----------------------------------------------------------------------

import { getUserName, getUserEmail } from "../authService";


const account = {
  displayName: getUserName(),
  email: getUserEmail(),
  photoURL: '/assets/images/avatars/avatar_default.jpg',
};

export default account;
