import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardContent, Avatar, Typography, Container, IconButton, Tooltip } from '@mui/material';
import { Email as EmailIcon } from '@mui/icons-material';
import axios from 'axios';
import { url } from '../utils/constants';

function NotificationsPage() {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifies = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${url}/api/notifications`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setNotifications(response.data);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifies();
  }, []);

  return (
    <Container maxWidth="md" style={{ marginTop: '20px' }}>
      {notifications.map((notification) => (
        <Card key={notification.id} style={{ marginBottom: '20px', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
          <CardHeader
            avatar={notification.action.includes('resposta') ? <Avatar src="/assets/icons/ic_notification_chat.svg" /> : ''}
            title={
              <Typography variant="h6" style={{ fontWeight: 'bold', color: '#333' }}>
                {notification.action}
              </Typography>
            }
            subheader={
              <Typography variant="body2" style={{ color: '#888' }}>
                {new Date(notification.createAt).toLocaleString()}
              </Typography>
            }
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary">
              {/* You can add more details about the notification here */}
              {notification.message}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{mt: 4}}>
              {/* You can add more details about the notification here */}
              <a href="#">checar</a>
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Container>
  );
}

export default NotificationsPage;
