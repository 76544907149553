import React, { useState } from 'react';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Container,
  Box,
  Typography
} from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { url } from '../utils/constants';

const RegisterUser = () => {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    password: '',
    role_id: '',
    status: true
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Aqui você faria o envio dos dados para o backend usando, por exemplo, axios ou fetch.
    try{
        const token = localStorage.getItem('token');

        await axios.post(`${url}/register`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });

        await Swal.fire({
            title: "Usuário registrado com sucesso",
            icon: "success",
            timer: 2000,
            zIndex: 9999
          })

        document.location.href = '/dashboard/user'
    }catch(e){
        console.log(e)
    }
  };

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Registro de Usuário
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          {/* Nome Completo */}
          <TextField
            fullWidth
            label="Nome Completo"
            value={formData.fullname}
            onChange={(e) => setFormData({ ...formData, fullname: e.target.value })}
            margin="normal"
            variant="outlined"
            required
          />

          {/* E-mail */}
          <TextField
            fullWidth
            label="E-mail"
            type="email"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            margin="normal"
            variant="outlined"
            required
          />

          {/* Senha */}
          <TextField
            fullWidth
            label="Senha"
            type="password"
            value={formData.password}
            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
            margin="normal"
            variant="outlined"
            required
          />

          {/* Papel (Role) */}
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="role-label">Papel</InputLabel>
            <Select
              labelId="role-label"
              id="role-select"
              value={formData.role_id}
              label="Papel"
              onChange={(e) => setFormData({ ...formData, role_id: e.target.value })}
            >
              <MenuItem value={1}>Administrador</MenuItem>
              <MenuItem value={2}>Editor</MenuItem>
              <MenuItem value={3}>Visualizador</MenuItem>
            </Select>
          </FormControl>

          {/* Status */}
          <FormControl fullWidth margin="normal">
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              value={formData.status ? 1 : 0}
              label="Status"
              onChange={(e) => setFormData({ ...formData, status: e.target.value === 1 })}
            >
              <MenuItem value={1}>Ativo</MenuItem>
              <MenuItem value={0}>Inativo</MenuItem>
            </Select>
          </FormControl>

          {/* Botão de envio */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Registrar
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default RegisterUser;
