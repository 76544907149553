import React, { useState } from 'react';
import { Box, TextField, Checkbox, RadioGroup, Radio, FormControlLabel, FormLabel, Button, Divider, IconButton } from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete" // Import the delete icon

function InputField({ input, updateInput, deleteInput, fileInputExists }) {
  const [type, setType] = useState(input.type || 'text');
  const [label, setLabel] = useState(input.label || '');
  const [required, setRequired] = useState(input.required || false);
  const [justify, setJustify] = useState(input.justify || false); // Justify state
  const [score, setScore] = useState(input.score || false); // Score state
  const [options, setOptions] = useState(input.options || []); // Options state
  const [points, setPoints] = useState(input.points || []); // Points state for each option

  const handleTypeChange = (e) => {
    const newType = e.target.value;

    if (newType === 'file' && fileInputExists()) {
      alert("Apenas um campo do tipo Arquivo é permitido");
      return;
    }
  
    setType(newType);
    updateInput({ ...input, type: newType });
  };

  const handleLabelChange = (e) => {
    setLabel(e.target.value);
    updateInput({ ...input, label: e.target.value });
  };

  const handleRequiredChange = (e) => {
    setRequired(e.target.checked);
    updateInput({ ...input, required: e.target.checked });
  };

  const handleJustifyChange = (e) => {
    setJustify(e.target.checked);
    updateInput({ ...input, justify: e.target.checked });
  };

  const handleScoreChange = (e) => {
    setScore(e.target.checked);
    updateInput({ ...input, score: e.target.checked });
  };

  const addOption = () => {
    setOptions([...options, '']);
    setPoints([...points, 0]); // Add corresponding score
  };

  const updateOption = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
    updateInput({ ...input, options: updatedOptions });
  };

  const updatePoints = (index, value) => {
    const updatedPoints = [...points];
    updatedPoints[index] = value;
    setPoints(updatedPoints);
    updateInput({ ...input, points: updatedPoints });
  };

  const removeOption = (index) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    const updatedPoints = points.filter((_, i) => i !== index); // Remove corresponding points
    setOptions(updatedOptions);
    setPoints(updatedPoints);
    updateInput({ ...input, options: updatedOptions, points: updatedPoints });
  };

  return (
    <Box sx={{ mb: 2 }}>
      <TextField
        label="Titulo do campo"
        fullWidth
        value={label}
        onChange={handleLabelChange}
        sx={{ mb: 1 }}
      />

      <FormLabel component="legend">Tipo do campo</FormLabel>
      <RadioGroup row value={type} onChange={handleTypeChange}>
        <FormControlLabel value="text" control={<Radio />} label="Texto" />
        <FormControlLabel value="number" control={<Radio />} label="Número" />
        <FormControlLabel value="file" control={<Radio />} label="Arquivo" />
        <FormControlLabel value="checkbox" control={<Radio />} label="Checkbox" />
        <FormControlLabel value="radio" control={<Radio />} label="Radio" />
        <FormControlLabel value="textArea" control={<Radio />} label="Caixa de texto" /> {/* New text area option */}
      </RadioGroup>

      {/* {type === 'textArea' && // Render a TextArea if type is 'textArea'
        <TextField
          label="Texto"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          required={required}
          onChange={(e) => updateInput({ ...input, value: e.target.value })} // Store the value in input state
        />
      }


      {type === 'file' && // Render a TextArea if type is 'textArea'
        <TextField
          label="Texto"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          required={required}
          onChange={(e) => updateInput({ ...input, value: e.target.value })} // Store the value in input state
        />
      } */}

      <FormControlLabel
        control={<Checkbox checked={required} onChange={handleRequiredChange} />}
        label="Obrigatório"
      />

      {required && (type === 'radio' || type === 'checkbox') && (
        <FormControlLabel
          control={<Checkbox checked={justify} onChange={handleJustifyChange} />}
          label="Justificar resposta"
        />
      )}

      {(type === 'radio' || type === 'checkbox') && (
        <FormControlLabel
          control={<Checkbox checked={score} onChange={handleScoreChange} />}
          label="Ativar esquema de pontos"
        />
      )}

      {(type === 'checkbox' || type === 'radio') && (
        <Box sx={{ mt: 2 }}>
          <FormLabel component="legend">Opções</FormLabel>
          {options.map((option, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <TextField
                label={`Opção ${index + 1}`}
                value={option}
                onChange={(e) => updateOption(index, e.target.value)}
                sx={{ mr: 2 }}
              />

              {score && (
                <TextField
                  label={`Ponto`}
                  value={points[index]}
                  onChange={(e) => updatePoints(index, e.target.value)}
                  sx={{ mr: 2 }}
                />
              )}

              <Button variant="outlined" color="error" onClick={() => removeOption(index)}>
                Apagar opção
              </Button>
            </Box>
          ))}
          <Button variant="outlined" onClick={addOption}>
            Adicionar opção
          </Button>
        </Box>
      )}

      <Divider sx={{ my: 2 }} />

      <IconButton color="error" onClick={deleteInput}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
}

export default InputField;
