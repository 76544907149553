import { GridToolbar } from '@mui/x-data-grid';
import { Button, TextField, Box, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { useState } from 'react';

const MultipleFiltersDataGrid = ({ apiRef }) => {
  const [filters, setFilters] = useState([{ field: '', operator: '', value: '' }]);

  const handleAddFilter = () => {
    setFilters([...filters, { field: '', operator: '', value: '' }]);
  };

  const handleFilterChange = (index, key, value) => {
    const newFilters = [...filters];
    newFilters[index][key] = value;
    setFilters(newFilters);
  };

  const handleApplyFilters = () => {
    apiRef.current.setFilterModel({
      items: filters.map((filter) => ({
        columnField: filter.field,
        operatorValue: filter.operator,
        value: filter.value,
      })),
    });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <GridToolbar />
      <Box sx={{ display: 'flex', gap: 2 }}>
        {filters.map((filter, index) => (
          <Box key={index} sx={{ display: 'flex', gap: 1 }}>
            <FormControl fullWidth>
              <InputLabel>Campo</InputLabel>
              <Select
                value={filter.field}
                onChange={(e) => handleFilterChange(index, 'field', e.target.value)}
                label="Campo"
              >
                <MenuItem value="Nome">Nome</MenuItem>
                <MenuItem value="IDContrato">Contrato</MenuItem>
                <MenuItem value="CNPJ">CNPJ</MenuItem>
                <MenuItem value="CPF">CPF</MenuItem>
                {/* Add more fields as needed */}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Operador</InputLabel>
              <Select
                value={filter.operator}
                onChange={(e) => handleFilterChange(index, 'operator', e.target.value)}
                label="Operador"
              >
                <MenuItem value="contains">Contém</MenuItem>
                <MenuItem value="equals">Igual</MenuItem>
                {/* Add more operators as needed */}
              </Select>
            </FormControl>
            <TextField
              label="Valor"
              value={filter.value}
              onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
            />
          </Box>
        ))}
      </Box>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button onClick={handleAddFilter}>Adicionar Filtro</Button>
        <Button onClick={handleApplyFilters}>Aplicar Filtros</Button>
      </Box>
    </Box>
  );
};

export default MultipleFiltersDataGrid;
