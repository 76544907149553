import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, TextField, Checkbox, FormControlLabel, RadioGroup, Radio, Button, Typography, Card, CardContent, Grid, Container } from '@mui/material';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Swal from 'sweetalert2';
import { url } from '../utils/constants';
import './FormRender.css';
import account from 'src/_mock/account';


// Custom styled Checkbox
const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.MuiCheckbox-root': {
    color: '#ccc',
  },
  '&.Mui-checked': {
    color: '#2980b9',
  },
  '&:hover': {
    backgroundColor: 'rgba(41, 128, 185, 0.08)',
  },
}));

// Custom styled Radio
const CustomRadio = styled(Radio)(({ theme }) => ({
  '&.MuiRadio-root': {
    color: '#ccc',
  },
  '&.Mui-checked': {
    color: '#2980b9',
  },
  '&:hover': {
    backgroundColor: 'rgba(41, 128, 185, 0.08)',
  },
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function FormRender() {
  const { id } = useParams(); 
  const navigate = useNavigate();
  const [justifications, setJustifications] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState([]);
  const [formId, setFormId] = useState(0);
  const [activeSection, setActiveSection] = useState(0); // Track active section
  const [base64File, setbase64File] = useState(null);

  const handleJustificationChange = (sectionIndex, inputIndex, value) => {
    setJustifications((prevState) => ({
      ...prevState,
      [`justify_${sectionIndex}_${inputIndex}`]: value
    }));
  };


  const handleInputChange = (sectionIndex, inputIndex, value) => {
    const key = `form_${formId}_progress`;

    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[sectionIndex].inputs[inputIndex].value = value;

      // Save updated state to localStorage
      localStorage.setItem(key, JSON.stringify(updatedFormData));

      return updatedFormData;
    });
  };

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${url}/api/form/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const formArray = Array.isArray(response.data.json_content)
          ? response.data.json_content
          : Object.values(response.data.json_content);

        document.title = response.data.name
        console.log(response.data)

        const savedProgress = localStorage.getItem(`form_${response.data.id}_progress`);
        const prefilledData = savedProgress ? JSON.parse(savedProgress) : formArray;

        setFormData(prefilledData);
        setFormId(response.data.id)
        console.log(response.data.id)
      } catch (error) {
        navigate('/404');
        console.error('Error fetching forms:', error);
      }
    };


    fetchForm();
  }, [id, navigate,formId]);

  const validateForm = (formValues) => {
    const errors = {};

    formData.forEach((section, sectionIndex) => {
      section.inputs.forEach((input, inputIndex) => {
        
        if (input.required) {
          if (input.type === 'checkbox') {
            const checkedValues = [];
            input.options.forEach((option, optionIndex) => {
              if (formValues.get(`input_${sectionIndex}_${inputIndex}_${optionIndex}`)) {
                checkedValues.push(option);
              }
            });
            if (checkedValues.length === 0) {
              errors[`input_${sectionIndex}_${inputIndex}`] = 'Pelo menos uma caixa precisa ser selecionada';
            }
          }

          if (input.type === 'radio') {
            const radioValue = formValues.get(`input_${sectionIndex}_${inputIndex}`);
            if (!radioValue) {
              errors[`input_${sectionIndex}_${inputIndex}`] = 'Seleção obrigatória';
            }
          }
        }
      });
    });

    setFormErrors(errors);
    
    return Object.keys(errors).length === 0;
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const formValues = new FormData(event.target);

  //   if (!validateForm(formValues)) {
  //     console.log('Form has errors, not submitting.');
  //     return;
  //   }

  //   const formObject = formData.map((section, sectionIndex) => ({
  //     section: section.title,
  //     fields: section.inputs.map((input, inputIndex) => {
  //       let value;
  //       let score = 0;
  //       if (input.type === 'checkbox') {
  //         const values = [];
  //         input.options.forEach((option, optionIndex) => {
  //           if (formValues.get(`input_${sectionIndex}_${inputIndex}_${optionIndex}`)) {
  //             values.push(option);
  //             const pointsValue = parseInt(input?.points[optionIndex], 10);
  //             score += isNaN(pointsValue) ? 0 : pointsValue;
  //           }
  //         });
  //         value = values;
  //       } else if (input.type === 'radio') {
  //         value = formValues.get(`input_${sectionIndex}_${inputIndex}`);
  //         const selectedOptionIndex = input?.options.findIndex(option => option === value);
  //         const selectedPoints = parseInt(input?.points[selectedOptionIndex], 10);
  //         score = isNaN(selectedPoints) ? 0 : selectedPoints;
  //       } else {
  //         value = formValues.get(`input_${sectionIndex}_${inputIndex}`);
  //       }

  //       return {
  //         label: input.label,
  //         value,
  //         justification: justifications[`justify_${sectionIndex}_${inputIndex}`] || null,
  //         score: { ...score }
  //       };
  //     })
  //   }));

  //   console.log('Detailed Form Submission:', formObject);
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formValues = new FormData(event.target);
  
    if (!validateForm(formValues)) {
      console.log('Form has errors, not submitting.');
      return;
    }
  
    const formObject = formData.map((section, sectionIndex) => ({
      section: section.title,
      fields: section.inputs.map((input, inputIndex) => {
        let value;
        let score = 0;
        // console.log(section)
  
        if (input.type === 'checkbox') {
          const values = [];
          input.options.forEach((option, optionIndex) => {
            if (formValues.get(`input_${sectionIndex}_${inputIndex}_${optionIndex}`)) {
              values.push(option);
              // Check if input.points is defined and has a value for the current index
              const pointsValue = input.points && input.points[optionIndex] ? parseInt(input.points[optionIndex], 10) : 0;
              score += isNaN(pointsValue) ? 0 : pointsValue;
            }
          });
          value = values;
        } else if (input.type === 'radio') {
          value = formValues.get(`input_${sectionIndex}_${inputIndex}`);
          const selectedOptionIndex = input.options.findIndex(option => option === value);
          // Check if input.points is defined and has a value for the selected index
          const selectedPoints = input.points && input.points[selectedOptionIndex] ? parseInt(input.points[selectedOptionIndex], 10) : 0;
          score = isNaN(selectedPoints) ? 0 : selectedPoints;
        } else {
          value = formValues.get(`input_${sectionIndex}_${inputIndex}`);
        }
  
        return {
          inputType: input.type,
          label: input.label,
          value,
          justification: justifications[`justify_${sectionIndex}_${inputIndex}`] || null,
          score: score // No need for {...score}, just return the score as a number
        };
      })
    }));
  
    const post = {
      json_content: formObject,
      file: base64File,
      form_id: formId
    }

    const token = localStorage.getItem('token');

    const headers = {
      Authorization: `Bearer ${token}`, // Send the token in the header
    }

    try{
      const response = await axios.post(`${url}/answer`, post, {headers: {...headers}});

      if (response.status === 201) {
        await Swal.fire({
          title: 'Resposta enviada com sucesso.',
          icon: 'success',
          showCancelButton: true,
          confirmButtonText: 'Enviar novamente',
          cancelButtonText: 'Continuar',
        }).then((result) => {
          if (result.isConfirmed) {
            // Reload the page to reset the form
            localStorage.removeItem(`form_${formId}_progress`);
            window.location.reload();
          } else {
            // Proceed with navigation
            localStorage.removeItem(`form_${formId}_progress`);
            navigate('/success');
          }
        });
      }

      // if(response.status === 201){
      //   await Swal.fire({
      //     title: 'Resposta enviada com sucesso.',
      //     icon: 'success',
      //     timer: 4000
      //   })

      //   localStorage.removeItem(`form_${formId}_progress`);
      //   navigate('/success')
      // }
    }catch (error) {
      await Swal.fire({
        title: 'Falha ao processar solicitação',
        icon: 'error',
        text: 'Ocorreu um erro ao enviar seu pedido, tente novamente ou contacte o administrador do sistema',
        timer: 4000
      })
    }
  };
  

  const handleScroll = () => {
    const sections = document.querySelectorAll('.form-section');
    let newActiveSection = -1; // Default to -1 (no section active)
  
    // Check if the scroll position is at the top
    if (window.scrollY === 0) {
      newActiveSection = 0; // Set active section to the first section
    } else {
      sections.forEach((section, index) => {
        const { top, bottom } = section.getBoundingClientRect();
  
        // Check if the section is fully in the viewport
        if (top >= 0 && bottom <= window.innerHeight) {
          newActiveSection = index; // Section is fully visible
        } 
        // Check if the section is crossing the middle of the viewport
        else if (top < window.innerHeight / 2 && bottom > window.innerHeight / 2) {
          newActiveSection = index; // Section is passing through the middle of the viewport
        }
      });
    }
  
    // Update the active section only if it has changed
    if (newActiveSection !== activeSection) {
      setActiveSection(newActiveSection);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown' && activeSection < formData.length - 1) {
      setActiveSection((prev) => {
        const newSection = prev + 1;
        window.scrollTo({ top: newSection * window.innerHeight, behavior: 'smooth' });
        return newSection; 
      });
    } else if (event.key === 'ArrowUp' && activeSection > 0) {
      setActiveSection((prev) => {
        const newSection = prev - 1;
        window.scrollTo({ top: newSection * window.innerHeight, behavior: 'smooth' });
        return newSection; 
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('keydown', handleKeyDown); 
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('keydown', handleKeyDown); 
    };
  }, [activeSection]);


  // Create the callback function
  const base64Callback = async (err, res) => {
    if (!err) {
      setbase64File(res); // setting the state is the important part 
      console.log(res)
    } else {
      setbase64File(null);
    }
  };

  function handleFileChange(event) {
    const file = event.target.files[0] || null; // Get the single selected file
    const allowedExtensions = ["image/png", "image/jpeg", "application/pdf"];
    
    event.target.value = null;

    if (!file) {
      return; // No file selected
    }
  
    // Check if the file type matches the allowed extensions
    if (!allowedExtensions.includes(file.type)) {
      alert(`Invalid file type: ${file.name}. Please upload PNG, JPG, or PDF files only.`);
      return; // Prevent further processing if file is invalid
    }
  
    // Check file size if needed (e.g., 2MB max)
    const maxSizeInMB = 2;
    if (file.size > maxSizeInMB * 1024 * 1024) {
      alert(`File is too large: ${file.name}. Please upload files smaller than 2MB.`);
      return;
    }

    getBase64(file, base64Callback)
  
  }

  const getBase64 = async (file, callback) => {
    const reader = new FileReader();
    reader.onload = () => callback(null, reader.result);
    reader.onerror = (error) => callback(error);
    reader.readAsDataURL(file);
  }

  return (
    <div className="body">
      <Container className="frc" maxWidth="md" sx={{ p: 3 }}>
        {/* Texto centralizado acima */}
        <div className="presentation">
          <Typography variant="h5" sx={{ fontWeight: 400, textAlign: 'center', mb: 2, color: '#fff' }}>
            Preenchendo formulário como <b style={{ color: '#f39c12' }}>{account.displayName}</b>
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: '500', textAlign: 'center', color: '#fff' }}>
            {document.title || 'Carregando...'}
          </Typography>
        </div>
        {formData.length > 0 && (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {formData.map((section, sectionIndex) => (
                <Grid item xs={12} key={sectionIndex}>
                  <Card
                    className="form-section"
                    sx={{
                      borderRadius: 3,
                      boxShadow: 3,
                      backgroundColor: '#fff',
                      p: 3,
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ fontWeight: 'bold', color: '#2c3e50', mb: 2 }}
                      >
                        {section.title}
                      </Typography>
                      {section.inputs.map((input, inputIndex) => (
                        <Box key={inputIndex} sx={{ mb: 3 }}>
                          {input.type === 'text' || input.type === 'number' ? (
                            <TextField
                              label={input.label}
                              required={input.required}
                              fullWidth
                              value={formData[sectionIndex]?.inputs[inputIndex]?.value || ''}
                              onChange={(e) => handleInputChange(sectionIndex, inputIndex, e.target.value)}
                              name={`input_${sectionIndex}_${inputIndex}`}
                              type={input.type}
                              variant="outlined"
                              sx={{ mb: 2 }}
                            />
                          ) : input.type === 'textArea' ? (
                            <TextField
                              label={`${input.label} (máx. 500 caracteres)`}
                              required={input.required}
                              multiline
                              rows={4}
                              fullWidth
                              value={formData[sectionIndex]?.inputs[inputIndex]?.value || ''}
                              onChange={(e) => handleInputChange(sectionIndex, inputIndex, e.target.value)}
                              name={`input_${sectionIndex}_${inputIndex}`}
                              variant="outlined"
                              inputProps={{ maxLength: 500 }}
                            />
                          ) : input.type === 'file' ? (
                            <>
                              <Button
                                component="label"
                                variant="contained"
                                startIcon={<CloudUploadIcon />}
                                sx={{
                                  backgroundColor: '#2980b9',
                                  '&:hover': { backgroundColor: '#3498db' },
                                }}
                              >
                                Enviar anexo (png, jpg, pdf)
                                <input
                                  hidden
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg, application/pdf"
                                  onChange={(event) => handleFileChange(event)}
                                  required={input.required}
                                />
                              </Button>
                              <Typography sx={{ color: '#666', mt: 1, fontSize: 13 }}>
                                <b>{input.label} <span style={{fontWeight: 300}}>{input.required ? '(Envio de anexo obrigatório)' : '(Envio opcional)'}</span></b>
                                
                              </Typography>
                            </>
                          ) : 
                          input.type === "checkbox" ? (
                            <Box>
                              <Typography sx={{ fontWeight: 500 }}>{input.label}</Typography>
                              {input.options.map((option, optionIndex) => (
                                <FormControlLabel
                                  key={optionIndex}
                                  control={
                                    <CustomCheckbox
                                      name={`input_${sectionIndex}_${inputIndex}_${optionIndex}`}
                                      checked={
                                        formData[sectionIndex]?.inputs[inputIndex]?.value?.includes(option) || false
                                      }
                                      onChange={(e) => {
                                        const value = e.target.checked;
                                        const currentValues =
                                          formData[sectionIndex]?.inputs[inputIndex]?.value || [];
                                        const updatedValues = value
                                          ? [...currentValues, option]
                                          : currentValues.filter((item) => item !== option);
                                        handleInputChange(sectionIndex, inputIndex, updatedValues);
                                      }}
                                    />
                                  }
                                  label={option}
                                />
                              ))}
                            {formErrors[`input_${sectionIndex}_${inputIndex}`] && (
                              <Typography color="error" sx={{ fontSize: '0.8rem', mt: 1 }}>
                                {formErrors[`input_${sectionIndex}_${inputIndex}`]}
                              </Typography>
                            )}
                            {input.required && input.justify && (
                              <TextField
                                fullWidth
                                label="Justifique sua resposta (máx. 500 caracteres)"
                                multiline
                                name={`justify_${sectionIndex}_${inputIndex}`}
                                rows={3}
                                
                                required
                                inputProps={{
                                  maxLength: 500,
                                }}
                                onChange={(e) => handleJustificationChange(sectionIndex, inputIndex, e.target.value)}
                                sx={{ mt: 1 }}
                                variant="outlined"
                              />
                            )}
                            </Box>
                          ) : input.type === "radio" ? (
                            <Box>
                              <Typography sx={{ fontWeight: 500 }}>{input.label}</Typography>
                              <RadioGroup
                                name={`input_${sectionIndex}_${inputIndex}`}
                                value={formData[sectionIndex]?.inputs[inputIndex]?.value || ""}
                                onChange={(e) => handleInputChange(sectionIndex, inputIndex, e.target.value)}
                              >
                                {input.options.map((option, optionIndex) => (
                                  <FormControlLabel
                                    key={optionIndex}
                                    value={option}
                                    control={<CustomRadio />}
                                    label={option}
                                  />
                                ))}
                              </RadioGroup>
                              {formErrors[`input_${sectionIndex}_${inputIndex}`] && (
                              <Typography color="error" sx={{ fontSize: '0.8rem', mt: 1 }}>
                                {formErrors[`input_${sectionIndex}_${inputIndex}`]}
                              </Typography>
                            )}
                            {input.required && input.justify && (
                              <TextField
                                fullWidth
                                label="Justifique sua resposta (máx. 500 caracteres)"
                                multiline
                                name={`justify_${sectionIndex}_${inputIndex}`}
                                rows={3}
                                inputProps={{
                                  maxLength: 500,
                                }}
                                required
                                onChange={(e) => handleJustificationChange(sectionIndex, inputIndex, e.target.value)}
                                sx={{ mt: 1 }}
                                variant="outlined"
                              />
                            )}
                            </Box>
                          ) : null}
                        </Box>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Button
              type="submit"
              variant="contained"
              sx={{
                mt: 4,
                width: '100%',
                py: 1.5,
                backgroundColor: '#2980b9',
                '&:hover': {
                  backgroundColor: '#3498db',
                },
              }}
            >
              Finalizar
            </Button>
          </form>
        )}
      </Container>
    </div>
  );
  
  
}

export default FormRender;
